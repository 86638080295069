body {
    margin: 0;
}

.scoreboard {
    background-color: #138548;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 15px;
    display: flex;
    justify-content: space-around;
    border: solid;
    border-color: white;
    -ms-overflow-style: none;
    scrollbar-width: none;

}

.scoreboard h2 {
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 2rem;
}

.scoreboard p {
    color: white;
    font-family: monospace;
    font-size: 1.5rem;
}

.goal-home,
.time,
.goal-visitor {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.goal-title {
    width: 100%;
    padding: 10px;
    border: dashed rgb(31, 31, 31);
    border-bottom: 0;
    margin: 0;
    text-align: center;
}

.goal-numb {
    margin: 0;
    width: 100%;
    padding: 10px;
    border: dashed rgb(31, 31, 31);
    border-top: 0;
    text-align: center;
}

.time-numb,
.time-period {
    margin: 10px;
}